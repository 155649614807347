import request from 'utils/request';
import { retryablePromise } from 'utils/generalUtils';
import { appendDataLayerValue, trackFormSubmit } from 'utils/trackingFunctions';
import { getUsersGoogleSessionIds } from 'app-requests/triadmsRequests';
import { getPageViewId, getUserSessionId } from 'utils/analyticsHelpers';
import { LogError, LogInfo, newrelicPageAction } from 'utils/logging';
import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { getDOMFieldValue } from 'utils/formValuesUtils';
import { formValuesToRequestArr } from 'utils/form-utils/formValuesToRequestArr';
import { FormValues, LeadSubmitResponse, Question } from 'types';
import { FIELD_NAMES } from 'consts';

interface FormConfigs {
  leadSubmitEndpoint?: string;
  leadEvalToken?: string;
  disclaimerText?: string;
  schoolCode: string;
  variant?: string;
}

let didSubmitThisSession = false;

/**
 * @summary This function is used to submit the lead for micro sites
 */
export async function submitMicroSiteLead(
  formValues: FormValues,
  fieldNameMap: Record<FIELD_NAMES, Question>,
  formConfigs: FormConfigs,
  metaData: { isClickUser: boolean }
): Promise<LeadSubmitResponse> {
  const { isClickUser } = metaData || {};
  const {
    schoolCode,
    variant,
    leadSubmitEndpoint = '/leadSubmit',
    disclaimerText,
    leadEvalToken,
  } = formConfigs;

  // TODO: look into this as GA shows a very large number compared to profile payable user events
  trackFormSubmit();

  // TODO: once backend is live with Conversion Tracking there is refactor needed so frontend does not send conversion events
  const metadata = getUsersGoogleSessionIds();

  if (!getUserSessionId() || !getPageViewId()) {
    LogError('No UserSessionId or PageViewId Found when submitting form', {
      sessionId: getUserSessionId(),
      pageViewId: getPageViewId(),
    });
  }

  newrelicPageAction('lead_submit', { requestStatus: 'started' });
  LogInfo('lead_submit', { description: 'started' });

  if (didSubmitThisSession) {
    LogError('Lead already submitted this session');
  }

  didSubmitThisSession = true;

  try {
    const { status, result = {} } = await retryablePromise(() =>
      request({
        method: 'post',
        // Setting to 120 seconds. Lead submit can take a long time and we should not timeout.
        // Cloudflare will timeout after 100 seconds
        responseTimeout: 120000,
        deadlineTimeout: 120000,
        url: `${TRIAD_PROXY_ROUTE}${leadSubmitEndpoint}`,
        body: {
          schoolCode,
          templateName: variant,
          tcpaText: disclaimerText,
          trustedFormUrl: getDOMFieldValue('xxTrustedFormCertUrl_0'),
          leadId: getDOMFieldValue('leadid_token'),
          questionReplies: formValuesToRequestArr(formValues, fieldNameMap),
          isClickUser,
          metadata,
          leadEvalToken,
        },
      })
    );

    // For a single lead submit, mainly micro sites
    appendDataLayerValue('profileId', result.ProfileId ?? 'NOT_FOUND');

    const isTestMode = result.Message?.includes('TEST MODE;');
    const isAccepted = result.Message?.startsWith('ACCEPTED');
    const isPending = result.Message?.includes('PENDING');
    const shouldTrackConversion = status === 'ok' && isAccepted && !isTestMode;

    if (shouldTrackConversion) {
      newrelicPageAction('lead_submit', {
        requestStatus: isPending ? 'success-pending' : 'success-accepted',
        isTestMode: isTestMode ? 'true' : 'false',
      });
      !isTestMode &&
        LogInfo('lead_submit', {
          description: isPending ? 'success-pending' : 'success-accepted',
        });
    } else {
      newrelicPageAction('lead_submit', {
        requestStatus: 'success-rejected',
        isTestMode: isTestMode ? 'true' : 'false',
      });
      !isTestMode &&
        LogInfo('lead_submit', { description: 'success-rejected' });
    }

    return {
      shouldTrackConversion,
      leadsSubmittedFor: [
        {
          leadGuid: result.ProfileId,
          statusMessage: result.Message,
          status,
          revenue: result.Revenue,
          adjustedRevenue: result.AdjustedRevenue,
          schoolCode,
        },
      ],
    };
  } catch (error: unknown) {
    newrelicPageAction('lead_submit', { requestStatus: 'failed' });
    LogInfo('lead_submit', { description: 'failed' });

    const error_ = error as Error & { trace?: string };

    LogError(`submitLead ${error_.message}`, {
      trace: error_.trace,
      endpoint: leadSubmitEndpoint,
    });
    throw error;
  }
}
