import get from 'lodash/get';
import { LogError } from 'utils/logging';
import { isNextStepDynamic } from 'components/form-wizards/basic-form/utils';
import { getFormStepValidationInfo } from 'utils/form-utils/getFormStepValidationInfo';
import {
  doPreviousStepsHaveVisibleQuestions,
  getUnansweredQuestions,
  getValidQuestions,
  getValidVisibleUnansweredQuestions,
} from 'utils/formValuesUtils';
import { trackQuestionAnswersOnStep } from 'utils/trackingFunctions';
import getVisibleQuestions from 'utils/form-utils/getVisibleQuestions';
import { setBrowserStepInHistory } from 'utils/form-utils/browserNavigation';

/**
 * @summary this function is used to push the user to the next step, note that is should never be called on the last step
 * @param {Object} state - current state
 * @param {Object} action.shouldSkipSteps - skip the step if its already answered
 */
export default function moveToNextStep(state, action = {}) {
  const {
    shouldSkipSteps,
    shouldStopAtQuestions = [],
    isThankyouPage,
    isNonUserAction,
    isResumeSessionAcknowledged,
    isSanityCheck,
  } = action;
  const { formConfigs, formValues, currentStepIndex } = state;
  const totalSteps = formConfigs.steps.length;
  const nextStepIndex = currentStepIndex + 1;
  const step = formConfigs.steps[nextStepIndex];
  const isResumingMode = !!shouldStopAtQuestions.length && shouldSkipSteps;
  const updatedFormStatus = {
    ...state.formStatus,
    completedSteps: {
      ...state.formStatus.completedSteps,
      [state.currentStepIndex]: true,
    },
    stepsValidationStatus: {
      ...state.formStatus.stepsValidationStatus,
      [state.currentStepIndex]: getFormStepValidationInfo(state),
    },
  };

  // gather context to submit in case of error
  const loggingContext = {
    formId: formConfigs.id,
    currentStepIndex,
    nextStepIndex,
  };

  // tracking question here because we want to do this even when we skip over them, if they have values (T1-3346)
  const allQuestionInStep = get(
    state,
    `formConfigs.steps[${currentStepIndex}].questions`,
    []
  );
  !isSanityCheck &&
    trackQuestionAnswersOnStep(allQuestionInStep, formValues, isResumingMode);

  // if this is the thank you page just return and set percent complete to 100
  if (isThankyouPage) {
    return { ...state, isThankyouPage: true };
  }

  // there was no step found for where we are trying to move to
  if (!step) {
    !isSanityCheck &&
      LogError(
        'BasicFormWizard moveToNextStep Error: Moving to unknown Step',
        loggingContext
      );

    // TODO: need to make sure recursive calls have the correct questions as well
    return state;
  }

  // at this point we need to get all valid question for the next step we want to move to
  const nextStepQuestions = getValidQuestions(
    step.questions,
    state.formValues,
    state.allQuestionsInForm,
    state.formStatus.dynamicOptions
  );

  // it could be that this step has no question that match current responses
  const visibleQuestions = getVisibleQuestions(nextStepQuestions, state);

  // before going forward validate the next step, it might have changed, unless it's the last step
  if (step !== formConfigs.steps[totalSteps - 1]) {
    updatedFormStatus.stepsValidationStatus[nextStepIndex] =
      getFormStepValidationInfo({
        formStatus: updatedFormStatus,
        currentStepIndex: nextStepIndex,
        currentQuestions: nextStepQuestions,
        formValues: state.formValues,
      });
  }

  if (!nextStepQuestions.length || !visibleQuestions.length) {
    return moveToNextStep(
      {
        ...state,
        currentStepIndex: nextStepIndex,
        formStatus: updatedFormStatus,
      },
      action
    );
  }

  setBrowserStepInHistory(
    nextStepIndex,
    isNonUserAction,
    formConfigs.steps.length
  );

  // Its possible that we want to skip over steps that the user has already filled out, isResumingMode is used for case were we want to stop at some questions then continue to push user forward after they answered it
  if (shouldSkipSteps || state.formStatus.isResumingMode) {
    const unansweredQuestions = getUnansweredQuestions(
      visibleQuestions,
      formValues
    );

    const shouldStopOnStep = nextStepQuestions.some(({ id }) =>
      shouldStopAtQuestions.includes(id)
    );

    if (!unansweredQuestions.length && !shouldStopOnStep) {
      return moveToNextStep(
        {
          ...state,
          currentStepIndex: nextStepIndex,
          formStatus: updatedFormStatus,
        },
        action
      );
    }
  }

  // In the case where the user does not want to resume their session
  try {
    if (shouldSkipSteps === false && isResumeSessionAcknowledged) {
      for (let index = currentStepIndex; index < totalSteps; index += 1) {
        const unansweredQuestions = getValidVisibleUnansweredQuestions(
          /* questions */ state.formConfigs.steps[index].questions,
          state
        );
        if (unansweredQuestions.length) {
          break;
        }
        updatedFormStatus.completedSteps[index] = true;
      }
    }
  } catch (error) {
    LogError('Failed to calculate what step the user last left on', error);
  }

  const updatedState = {
    ...state,
    title: step.title || formConfigs.title,
    subTitle: step.subTitle || formConfigs.subTitle,
    currentStepIndex: nextStepIndex,
    isLastStep: step === formConfigs.steps[totalSteps - 1],
    isFirstStep: !doPreviousStepsHaveVisibleQuestions(state),
    currentQuestions: nextStepQuestions,
    totalSteps,
    ctaText: step.ctaText || 'Continue',
    currentAction: 'next',
    isNextStepDynamic: isNextStepDynamic(state, nextStepIndex),
    formStatus: {
      ...updatedFormStatus,
      isResumeSessionAcknowledged:
        isResumeSessionAcknowledged ||
        state.formStatus.isResumeSessionAcknowledged,
      // if this is true we will keep skipping questions up to an empty one
      isResumingMode,
      willAutoAdvance: false,
    },
  };

  updatedState.isFirstStep = !doPreviousStepsHaveVisibleQuestions(updatedState);
  // TODO: this is a bit of a hack, we need to find a better way to handle this. Write Unit Tests
  updatedState.isSecondStep = state.isFirstStep && !updatedState.isFirstStep;
  return updatedState;
}
