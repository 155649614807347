import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { FORM_ANIMATION_SPEED } from 'consts';

interface CSSsettings {
  formSpeed: number;
  megaMenuAnimationSpeed: number;
  toolTipAnimationEnterSpeed: number;
  toolTipAnimationExitSpeed: number;
  faqPanelAnimationSpeed: number;
  toolTipViewPortOffset: number;
}

/**
 * Allows for shared variables between the CSS in an individual theme and a component.
 * Runs on breakpoint change
 * Returns an object of settings that are configured in the CSS.
 *
 * @param {React.node} scopeRef optional ref to use for the CSS Custom Property scope. If empty defaults to the themeWrapperRef
 * @return {Object}
 */
function useCSSsettings(scopeRef?: React.RefObject<HTMLElement>): CSSsettings {
  const {
    windowSize: { currentBreakpoint },
    refs: { themeWrapperRef },
  } = useContext(GlobalContext);

  const [settings, setSettings] = useState({
    formSpeed: FORM_ANIMATION_SPEED,
    megaMenuAnimationSpeed: 250,
    toolTipAnimationEnterSpeed: 500,
    toolTipAnimationExitSpeed: 500,
    faqPanelAnimationSpeed: 250,
    toolTipViewPortOffset: 25,
  });

  const currentRef = scopeRef || themeWrapperRef;

  useEffect(() => {
    currentRef?.current &&
      setSettings({
        ...settings,
        formSpeed: parseInt(
          getComputedStyle(currentRef.current).getPropertyValue(
            '--formAnimationSpeed'
          ),
          10
        ),
        megaMenuAnimationSpeed: parseInt(
          getComputedStyle(currentRef.current).getPropertyValue(
            '--megaMenuAnimationSpeed'
          ),
          10
        ),
        toolTipAnimationEnterSpeed: parseInt(
          getComputedStyle(currentRef.current).getPropertyValue(
            '--toolTipAnimationEnterSpeed'
          ),
          10
        ),
        toolTipAnimationExitSpeed: parseInt(
          getComputedStyle(currentRef.current).getPropertyValue(
            '--toolTipAnimationExitSpeed'
          ),
          10
        ),
        toolTipViewPortOffset: parseInt(
          getComputedStyle(currentRef.current).getPropertyValue(
            '--toolTipViewPortOffset'
          ),
          10
        ),
      });
  }, [currentRef, currentBreakpoint]);

  return settings;
}


export default useCSSsettings;
