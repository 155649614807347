import get from 'lodash/get';
import { getValidQuestions } from 'utils/formValuesUtils';
import { DEFAULT_SELECT_VALUE } from 'consts';
import { formValuesToRequestArr } from 'utils/form-utils/formValuesToRequestArr';

export function getDepIds(question) {
  const deps = get(question, 'dependency', {});
  const depType = deps.DYNAMIC_VISIBILITY || deps.DYNAMIC_OPTIONS;
  return get(depType, 'questionIds', []);
}

/**
 * @summary This function is used to get a list of questions that we want to get dynamic options for
 * @param {Array} questions - an array that holds all questions that require dynamic options
 * @param {Object} formValues - all values in the form keyed by the form field name
 * @param {Object} questionsMap - an object that holds all questions in the form, keyed by the id
 */
export function getReadyDynOptQuestions(questions, formValues, questionsMap) {
  // use this to make sure the user was able to answer the question, if not then it does not count
  const availableQuestionsIds = getValidQuestions(
    Object.values(questionsMap),
    formValues,
    questionsMap
  ).map(({ id }) => id);

  // list of all questions that have DYNAMIC_OPTIONS and are ready to be called
  const allReady = questions.filter((question) => {
    // the list of question ids that this questions needs
    const depQuestionIds = getDepIds(question);

    // if any question that this question is dependend on are not answered, filter it out
    for (let i = 0; i < depQuestionIds.length; i += 1) {
      // it could be that the dependant question if not in the form, as its being AB Testested so we skip
      const { name } = questionsMap[depQuestionIds[i]];

      if (
        !formValues[name] ||
        get(formValues[name], 'value') === DEFAULT_SELECT_VALUE.value
      ) {
        const isQuestionAvailable = availableQuestionsIds.includes(
          depQuestionIds[i]
        );

        if (isQuestionAvailable) {
          return false;
        }
      }
    }

    return true;
  });

  return allReady;
}

/**
 * @summary This function is used to get a list of questions that have some field as a dependency
 * @param {Array} questions - an array that holds questions
 * @param {Object} fieldName - some field name
 * @param {Object} questionsMap - an object that holds all questions in the form, keyed by the id
 */
export function getQuestionsWithFieldAsDependency(
  questions,
  fieldName,
  questionsMap
) {
  return questions.filter((question) => {
    const depIds = getDepIds(question);
    for (let index = 0; index < depIds.length; index += 1) {
      const _question = questionsMap[depIds[index]];
      if (_question && _question.name === fieldName) {
        return true;
      }
    }
    return false;
  });
}

/**
 * @summary This function is used to prep any questions that need options for the API call
 * @param {Object} args.allDynamicOptionQuestions - an object that holds all questions that require dynamic options
 * @param {Object} args.formValues - all values in the form keyed by the form field name
 * @param {Object} args.allQuestionsInForm - an object that holds all questions in the form, keyed by the id
 * @param {String?} args.updatedValue - The field name that was updated, Optional if you want to filter the questions that could have changed
 */
export default function handleDynamicOptions(formState) {
  const {
    allQuestionsInForm,
    allDynamicOptionQuestions,
    formValues,
    updatedValue,
    fieldNameMap,
  } = formState;

  // only get the ones that can change due to the current field change
  const questionThatCanChange = updatedValue
    ? getQuestionsWithFieldAsDependency(
        allDynamicOptionQuestions,
        updatedValue,
        allQuestionsInForm
      )
    : allDynamicOptionQuestions;

  // get all questions that are ready to get options for
  const questionsNeedingOptions = getReadyDynOptQuestions(
    questionThatCanChange,
    formValues,
    allQuestionsInForm
  );

  // if node then return null
  if (!questionsNeedingOptions.length) {
    return null;
  }

  const requestedOptions = questionsNeedingOptions.map(({ id }) => id);

  return {
    // uniqBy will de-dup questions object
    questionReplies: formValuesToRequestArr(formValues, fieldNameMap),
    requestedOptions,
  };
}
