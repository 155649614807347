import React, { useRef, useState, useContext, useCallback } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import debounce from 'lodash/debounce';
import cx from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import { CSSTransition } from 'react-transition-group';
import useOutsideClick from 'hooks/custom/useOutsideClick';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';

interface ToolTipProps {
  children: React.ReactNode;
  label: string;
  openOnHover?: boolean;
  positioning: 'top' | 'bottom' | 'left' | 'right';
}

interface ToolTipPosition extends React.CSSProperties {
  '--toolTipLeft'?: string;
  '--toolTipWidth'?: string;
  '--toolTipHeight'?: string;
  '--toolTipTriggerOffset'?: string;
}

function ToolTip({
  children,
  label,
  openOnHover = false,
  positioning = 'bottom',
}: ToolTipProps): React.JSX.Element {
  const { windowSize } = useContext(GlobalContext);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [toolTipPos, setToolTipPos] = useState<ToolTipPosition>({});
  const toolTipRef = useRef<HTMLButtonElement>(null);
  const {
    toolTipAnimationEnterSpeed,
    toolTipAnimationExitSpeed,
    toolTipViewPortOffset,
  } = useCSSsettings(toolTipRef);

  function handleShowHide(toolTipIsVisible: boolean): () => void {
    return debounce(() => setIsVisible(toolTipIsVisible), 100);
  }

  const handleOutsideClick = useCallback(() => {
    if (isVisible) {
      setIsVisible(false);
    }
  }, [isVisible]);

  useOutsideClick(toolTipRef, handleOutsideClick);

  const isMobile = windowSize.currentBreakpoint === 'mobile';

  function getToolTipPosition(contentRef: HTMLElement): void {
    const toolTipContentRef = contentRef;

    if (
      !toolTipContentRef ||
      !toolTipContentRef.offsetWidth ||
      !toolTipRef.current ||
      !windowSize.width
    ) {
      return;
    }

    const { width: viewportWidth } = windowSize;
    const height = toolTipContentRef?.scrollHeight;

    const toolTipWidth = toolTipRef.current?.offsetWidth;
    const triggerButtonLeftPosition =
      toolTipRef.current?.getBoundingClientRect().left;

    // Calculate width first
    const contentWidth = Math.min(
      toolTipContentRef?.offsetWidth,
      viewportWidth - toolTipViewPortOffset * 2
    );

    // Calculate left position
    let left = -(contentWidth / 2) + toolTipWidth / 2;

    // Ensure tooltip doesn't go beyond right edge
    if (
      triggerButtonLeftPosition + left + contentWidth >
      viewportWidth - toolTipViewPortOffset
    ) {
      left =
        viewportWidth -
        toolTipViewPortOffset -
        contentWidth -
        triggerButtonLeftPosition;
    }

    // Ensure tooltip doesn't go beyond left edge
    if (triggerButtonLeftPosition + left < toolTipViewPortOffset) {
      left = toolTipViewPortOffset - triggerButtonLeftPosition;
    }

    setToolTipPos({
      '--toolTipLeft': `${left}px`,
      '--toolTipWidth': `${contentWidth}px`,
      '--toolTipHeight': `${height}px`,
      '--toolTipTriggerOffset': `${(left - toolTipWidth / 2) * -1}px`,
    });
  }

  const toolTipClass = cx({
    toolTip: true,
    'toolTip--isVisible': isVisible,
    [`toolTip--position${capitalizeFirstLetter(positioning)}`]: !!positioning,
  });

  return (
    <span className={toolTipClass} role="tooltip">
      <button
        type="button"
        className="toolTip__trigger"
        ref={toolTipRef}
        onMouseEnter={
          openOnHover && !isMobile ? handleShowHide(true) : undefined
        }
        onMouseLeave={
          openOnHover && !isMobile ? handleShowHide(false) : undefined
        }
        onFocus={openOnHover && !isMobile ? handleShowHide(true) : undefined}
        onBlur={!isMobile ? handleShowHide(false) : undefined}
        onClick={
          !openOnHover || isMobile ? handleShowHide(!isVisible) : undefined
        }
      >
        <span className="toolTip__triggerText">{label}</span>
      </button>

      <CSSTransition
        in={isVisible}
        className="toolTip__content"
        classNames="toolTip__content"
        timeout={{
          enter: toolTipAnimationEnterSpeed,
          exit: toolTipAnimationExitSpeed,
        }}
        aria-hidden={!isVisible}
        onEnter={(nodeRef: HTMLElement) => getToolTipPosition(nodeRef)}
        mountOnEnter
        unmountOnExit
      >
        <div style={toolTipPos}>
          <div className="toolTip__inner">
            {isMobile && (
              <button
                type="button"
                className="toolTip__closeBtn"
                onClick={isMobile ? handleShowHide(!isVisible) : undefined}
              >
                <span className="toolTip__closeBtnText">Close</span>
              </button>
            )}
            <div className="toolTip__contentText">{children}</div>
          </div>
        </div>
      </CSSTransition>
    </span>
  );
}

export default ToolTip;
