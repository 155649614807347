/* eslint-disable camelcase */
import { FIELD_NAMES } from 'consts';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import React, { useContext, useMemo } from 'react';
import { ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  FormFieldProps,
  GoogleAutoCompleteResult,
  GooglePlace,
  TriadGooglePlaceApiResponse,
} from 'types';
import { LogError } from 'utils/logging';
import promiseDebounce from 'utils/promiseDebounce';
import request from 'utils/request';
import { getGooglePlaceAddressParts } from './getGooglePlaceAddressParts';

const { CITY, STATE, STREET_ADDRESS, ZIP } = FIELD_NAMES;

export const debouncedGetGooglePlaces: (
  searchQuery: string
) => Promise<TriadGooglePlaceApiResponse<GoogleAutoCompleteResult[]>> =
  promiseDebounce(
    (searchQuery: string) =>
      request({ url: '/api/google/placeAutocomplete', query: { searchQuery } }),
    800
  );

/**
 * @summary Google Address Lookup component
 */
export function GoogleAddressLookup(props: FormFieldProps): JSX.Element {
  const { name, value, onChange, onFocus, onBlur } = props;
  const [options, setOptions] = React.useState<GooglePlace[]>([]);
  const { actions } = useContext(BasicFormWizardContext);

  const currentValue = useMemo(() => {
    if (!value || typeof value === 'string' || Array.isArray(value)) {
      return null;
    }

    return { ...value, value: value.value as GooglePlace };
  }, [value]);

  const _onChange = async (
    selection: ValueType<GooglePlace, false>
  ): Promise<void> => {
    if (selection && selection.placeId) {
      actions.setStatus({ isProcessing: true });
      const {
        data: googlePlace,
        success,
        error,
      }: TriadGooglePlaceApiResponse<GooglePlace | null> = await request({
        url: '/api/google/placeDetails',
        query: { placeId: selection.placeId },
      });

      if (!success || !googlePlace) {
        LogError('GoogleAddressLookup: Failed to fetch Google Place details', {
          error,
        });
        return;
      }

      const { street, city, zip, state } =
        getGooglePlaceAddressParts(googlePlace);

      onChange(zip, { name: ZIP, shouldSkipFormChangeFlow: true });
      onChange(city, { name: CITY, shouldSkipFormChangeFlow: true });
      onChange(state, { name: STATE, shouldSkipFormChangeFlow: true });
      onChange(street, {
        name: STREET_ADDRESS,
        shouldSkipFormChangeFlow: true,
      });

      if (currentValue) {
        onChange({ ...currentValue, value: googlePlace }, { name });
      } else {
        onChange({ value: googlePlace }, { name });
      }

      actions.setStatus({ isProcessing: false });
    } else {
      LogError('GoogleAddressLookup: Invalid selection', { selection });
    }
  };

  function handleLoadOptions(inputValue: string): Promise<GooglePlace[]> {
    return debouncedGetGooglePlaces(inputValue)
      .then((response) => {
        const googlePlaces = response.data.map((result) => ({
          formattedAddress: result.placePrediction.text.text,
          placeId: result.placePrediction.placeId,
          addressComponents: [],
          location: { latitude: 0, longitude: 0 },
        }));

        setOptions(googlePlaces);
        return googlePlaces;
      })
      .catch((error) => {
        LogError('Failed to fetch Google Places', { error: error.message });
        return [];
      });
  }

  return (
    <AsyncSelect
      isSearchable
      cacheOptions
      menuShouldScrollIntoView
      defaultOptions={options}
      tabIndex="0"
      loadOptions={handleLoadOptions}
      name={name}
      classNamePrefix="select"
      onChange={_onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      minMenuHeight={225}
      inputId="google-address-lookup"
      value={currentValue?.value}
      getOptionLabel={(option: GooglePlace) => option.formattedAddress}
      getOptionValue={(option: GooglePlace) => option.formattedAddress}
      formatOptionLabel={(option: GooglePlace) => option.formattedAddress}
    />
  );
}
