/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import GlobalContext from 'hooks/contexts/GlobalContext';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import { FormFieldProps } from 'types';
import { FIELD_NAMES } from 'consts';

interface DisclaimerProps {
  name: string;
  required: boolean;
  onChange: FormFieldProps['onChange'];
  value: boolean;
  disabled?: boolean;
  disclaimerTextValue?: string;
}

export default function Disclaimer(props: DisclaimerProps): React.ReactElement {
  const {
    name,
    required,
    onChange,
    value = false,
    disabled,
    disclaimerTextValue = '',
  } = props;
  const {
    siteMeta: { disclaimerText },
  } = useContext(GlobalContext);

  const tokenizedDisclaimerText = useTokenReplacement(
    disclaimerTextValue || disclaimerText
  );

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // T1-7146: validation issue. False is a valid value.
    onChange(event.target.checked as unknown as string, {
      name: event.target.name as FIELD_NAMES,
    });
  };

  return (
    <div
      className={required ? 'disclaimer disclaimer--isRequired' : 'disclaimer'}
    >
      {required && (
        <input
          disabled={disabled}
          checked={value}
          onChange={_onChange}
          className="disclaimer__input"
          type="checkbox"
          id="disclaimerInput"
          name={name}
          data-testid="disclaimer"
          data-tf-element-role="consent-opt-in"
        />
      )}
      <label
        htmlFor={required ? 'disclaimerInput' : 'leadid_tcpa_disclosure'}
        className="disclaimer__label"
      >
        {required && <span className="disclaimer__box" />}
        <span
          className="disclaimer__labelText"
          data-tf-element-role="consent-language"
        >
          <Wysiwyg content={tokenizedDisclaimerText} />
        </span>
      </label>
      {/* Don't touch this as jornaya needs it */}

      <input
        type="hidden"
        id="leadid_tcpa_disclosure"
        name="leadid_tcpa_disclosure"
      />
    </div>
  );
}
