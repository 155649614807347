/* eslint-disable react/prop-types */
import React, { useState, useContext, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cx from 'utils/classnames';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import ProgressBar from 'components/base/progressBar/ProgressBar';
import { shouldShowContinueButton } from 'utils/formValuesUtils';
import { scrollTo } from 'utils/generalUtils';
import { getTransitionInStatus } from 'components/form-wizards/basic-form/utils';
import GlobalContext from 'hooks/contexts/GlobalContext';
import LoadingButton from 'components/base/buttons/loadingButton/LoadingButton';
import FormStep from 'components/form-wizards/basic-form/FormStep';
import FormContainerV1 from 'components/form-wizards/containers/FormContainerV1';
import { FormFieldValidationStatus } from 'consts';
import Steps from 'components/base/steps/Steps';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import { SkipButton } from 'components/base/buttons/skipButton/SkipButton';

const { INVALID } = FormFieldValidationStatus;

function Form(props) {
  const {
    currentAction,
    currentQuestions,
    currentStepIndex,
    className,
    formStatus,
    getNextButtonText,
    handleSubmit,
    isFirstStep = true,
    isNextDisabled,
    percentComplete,
    showSidebar,
    showSteps,
    steps,
    stepsCount,
    title,
    variant,
    isLastStep,
  } = props;

  const [formStepHeight, setFormStepHeight] = useState(null);
  const [formIsTransitioning, setFormIsTransitioning] = useState(false);
  const wizardRef = useRef(null);

  const {
    windowSize: { currentBreakpoint },
    refs: { stepsContainerRef },
  } = useContext(GlobalContext);

  const { formSpeed } = useCSSsettings(wizardRef);

  const basicFormWizardClass = cx({
    basicFormWizard: true,
    [`basicFormWizard--${className}`]: !!className,
    'basicFormWizard--isFullPage': true,
    'basicFormWizard--isFirstStep': isFirstStep,
    'basicFormWizard--stepIsValid':
      formStatus.stepsValidationStatus[currentStepIndex]?.status !== INVALID,
    'basicFormWizard--stepIsNotValid':
      formStatus.stepsValidationStatus[currentStepIndex]?.status === INVALID,
  });

  const stepContainerClass = cx({
    basicFormWizard__stepContainer: true,
    [`basicFormWizard__stepContainer--${currentAction}`]: currentAction,
    'basicFormWizard__stepContainer--isTransitioning': formIsTransitioning,
  });

  return (
    <div
      className={basicFormWizardClass}
      ref={wizardRef}
      data-tf-element-role="offer"
    >
      <div className="basicFormWizard__container">
        <CSSTransition
          in
          mountOnEnter
          appear
          timeout={formSpeed}
          className="basicFormWizard__innerContainer"
          classNames="basicFormWizard__innerContainer"
        >
          <div>
            {formStatus.formError && (
              <div className="basicFormWizard__formError">
                {formStatus.formError}
              </div>
            )}

            <div className="basicFormWizard__head">
              <CSSTransition
                in={getTransitionInStatus(formStatus, isFirstStep)}
                appear
                mountOnEnter
                unmountOnExit
                timeout={formSpeed}
                className="basicFormWizard__title"
                classNames="basicFormWizard__title"
              >
                <h1>{title}</h1>
              </CSSTransition>
              <CSSTransition
                in={getTransitionInStatus(formStatus, isFirstStep)}
                appear
                mountOnEnter
                unmountOnExit
                timeout={formSpeed}
                className="basicFormWizard__progressBar"
                classNames="basicFormWizard__progressBar"
              >
                <div>
                  <ProgressBar
                    currentStepIndex={
                      steps ? steps[currentStepIndex].trueStepIndex : 0
                    }
                    totalSteps={stepsCount}
                    progress={percentComplete}
                  />
                </div>
              </CSSTransition>
              {showSteps && (
                <Steps
                  currentStepIndex={
                    steps ? steps[currentStepIndex].trueStepIndex : 0
                  }
                  steps={steps}
                  stepsContainerRef={stepsContainerRef}
                />
              )}
            </div>
            <div
              className={stepContainerClass}
              style={{ minHeight: `${formStepHeight + 20}px` }}
            >
              <TransitionGroup component={null}>
                <CSSTransition
                  in={getTransitionInStatus(formStatus, isFirstStep)}
                  key={`basicFormWizard__step_${currentStepIndex}`}
                  classNames="basicFormWizard__step"
                  className="basicFormWizard__step"
                  timeout={formSpeed}
                  onEntering={(node) => {
                    setFormStepHeight(node.clientHeight);
                    setFormIsTransitioning(true);
                  }}
                  onEntered={() => {
                    if (formStatus.isDirty) {
                      scrollTo(0);
                    }
                  }}
                  onExited={() => {
                    setFormIsTransitioning(false);
                  }}
                >
                  <div>
                    <DefaultErrorBoundary meta={{ currentStepIndex, variant }}>
                      <FormStep
                        questions={currentQuestions}
                        stepLabel={steps[currentStepIndex].groupLabel}
                        stepIndex={currentStepIndex}
                        formIsDirty={formStatus.isDirty}
                      />
                    </DefaultErrorBoundary>
                    <div className="basicFormWizard__ctaContainer">
                      {(!isFirstStep &&
                        (formStatus.isDirty ||
                          formStatus.isResumeSessionAcknowledged) &&
                        currentBreakpoint === 'mobile') ||
                        (!isFirstStep &&
                          (formStatus.isDirty ||
                            formStatus.isResumeSessionAcknowledged) &&
                          showSidebar && (
                            <button
                              type="button"
                              onClick={() => window.history.back()}
                              className="basicFormWizard__backBtn"
                              disabled={
                                formIsTransitioning || formStatus.isSubmitting
                              }
                            >
                              Previous
                            </button>
                          ))}
                      {shouldShowContinueButton(
                        currentQuestions,
                        currentBreakpoint
                      ) && (
                        <LoadingButton
                          onClick={handleSubmit}
                          className="basicFormWizard__nextBtn"
                          disabled={isNextDisabled}
                          loading={isNextDisabled}
                          text={getNextButtonText()}
                          isSubmit={isLastStep}
                        />
                      )}
                      <SkipButton
                        disabled={isNextDisabled}
                        loading={isNextDisabled}
                      />
                    </div>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
            {!isFirstStep && currentBreakpoint !== 'mobile' && !showSidebar && (
              <div className="basicFormWizard__nav">
                <button
                  type="button"
                  onClick={() => window.history.back()}
                  className="basicFormWizard__backBtn"
                  disabled={formIsTransitioning || formStatus.isSubmitting}
                >
                  <span className="basicFormWizard__backBtnIcon">
                    <svg
                      viewBox="0 0 7 12"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                    >
                      <path
                        d="M6.473.346c.446.436.481 1.043 0 1.576L2.726 5.83l3.747 3.908c.481.533.446 1.141 0 1.574-.445.436-1.197.408-1.615 0C4.44 10.906.356 6.617.356 6.617a1.095 1.095 0 010-1.576S4.44.754 4.858.346c.418-.408 1.17-.436 1.615 0z"
                        fillRule="nonzero"
                      />
                    </svg>
                  </span>
                  <span className="basicFormWizard__backBtnText">Previous</span>
                </button>
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default function FullPageFormWizard(props) {
  return <FormContainerV1 {...props} Form={Form} />;
}
