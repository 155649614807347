import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { clickPortalRedirect, QUESTION_IDS, FIELD_NAMES } from 'consts';
import GlobalContext from 'hooks/contexts/GlobalContext';
import cx from 'utils/classnames';

import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { MultiStepLoader } from 'components/base/inputs/multiSchoolSelect/MultiStepLoader';
import {
  FormFieldProps,
  FormFieldValueObject,
  MultiSchoolSelectionState,
  School,
  SchoolSelectionState,
} from 'types';
import { validateSchoolSkipOrSubmit } from 'utils/fieldValidations';
import { useRouter } from 'next/navigation';
import { handleConversionTracking } from 'utils/handleConversionTracking';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import { LogInfo } from 'utils/logging';
import { useMultiSchoolSelect } from './useMultiSchoolSelect';

import MultiSchoolSelectContext from './multiSchoolSelectContext';
import MultiSchoolSelectList from './MultiSchoolSelectList';

const { PRIMARY_PHONE } = QUESTION_IDS;
const { SECONDARY_PHONE } = FIELD_NAMES;
/**
 * Renders a list of SelectionCards based on the provided options.
 * Additionally renders a "cart" that displays the schools that have been submitted.
 */
export function MultiSchoolSelect(
  props: FormFieldProps<School>
): React.JSX.Element {
  const { options, name, error, label } = props;
  const didPrefetch = useRef(false);
  const router = useRouter();
  const {
    handleChange,
    formValues,
    allQuestionsInForm,
    fieldNameMap,
    formStatus: { isProcessing, dynamicOptions, isSubmitting },
    handleSubmit,
    formConfigs,
  } = useContext(BasicFormWizardContext);
  const {
    siteMeta: { disclaimerSuccessText },
    flags,
    actions,
    windowSize,
    sessionInfo: { floodLightActivityFilters },
    siteMeta: { floodlightActivityValues },
  } = useContext(GlobalContext);
  const isMobile = windowSize.currentBreakpoint !== 'desktop';
  const currentQuestionId = fieldNameMap?.[name]?.id;
  const [isTransitioning, setIsTransitioning] = useState(false);
  let phoneNumber = '';
  let secondaryPhoneNumber = '';
  if (typeof formValues[SECONDARY_PHONE] === 'string') {
    secondaryPhoneNumber = formValues[SECONDARY_PHONE];
  }

  if (allQuestionsInForm?.[PRIMARY_PHONE]?.name) {
    const phoneFieldName = allQuestionsInForm[PRIMARY_PHONE].name;
    phoneNumber = formValues[phoneFieldName] as string;
  }

  const LOADER_MIN_DURATION = 3000;

  const [showLoader, setShowLoader] = useState(isProcessing);
  const { formSpeed } = useCSSsettings();

  const updateMultiSchoolSelectFormValue = useCallback(
    (multiSchoolSelectFormValue: MultiSchoolSelectionState) => {
      const formValue = Object.keys(multiSchoolSelectFormValue).reduce(
        // Only storing minimal state for validation
        (acc, key: string) => {
          acc[key] = {
            isAccepted: multiSchoolSelectFormValue[key].isAccepted,
            isSkipped: multiSchoolSelectFormValue[key].isSkipped,
          };
          return acc;
        },
        {} as Record<string, Partial<SchoolSelectionState>>
      );

      // setTimeout because this need to be deferred to after MultiSchoolSelect is rendered
      setTimeout(() => {
        let impressionGroupGuid;

        if (Object.keys(multiSchoolSelectFormValue).length > 0) {
          const [firstOption] = Object.values(multiSchoolSelectFormValue);
          impressionGroupGuid = firstOption.schoolInfo.impressionGroupGuid;
        }

        handleChange(
          {
            // MAJOR HACK: Casting to string because we did not have proper typing and would require alot of refactoring to fix
            value: formValue as unknown as string,
            valueToSubmit: impressionGroupGuid,
            label,
          },
          { name }
        );
      });
    },
    [name]
  );

  const multiSchoolSelectState = useMultiSchoolSelect(
    options || [],
    updateMultiSchoolSelectFormValue,
    disclaimerSuccessText,
    phoneNumber,
    secondaryPhoneNumber,
    (leadResults) =>
      handleConversionTracking(leadResults, formConfigs, {
        floodLightActivityFilters,
        floodlightActivityValues,
      })
  );

  const { state, handleNextClick } = multiSchoolSelectState;

  // AutoSubmit
  useEffect(() => {
    const formField = formValues[
      name
    ] as FormFieldValueObject<MultiSchoolSelectionState>;
    const haveDynamicOptionsBeenLoaded =
      currentQuestionId &&
      typeof dynamicOptions[currentQuestionId] !== 'undefined';

    if (!didPrefetch.current) {
      router.prefetch(clickPortalRedirect);
      didPrefetch.current = true;
    }

    if (
      haveDynamicOptionsBeenLoaded &&
      options?.length === 0 &&
      !isProcessing
    ) {
      router.push(clickPortalRedirect);
    }

    if (formField && formField.value) {
      const validationError = validateSchoolSkipOrSubmit({
        name,
        value: formField.value,
      });
      if (!validationError[name]) {
        handleSubmit();
      }
    }
  }, [formValues, name, isProcessing, dynamicOptions]);

  // Update global flags when a card is expanded
  useEffect(() => {
    const isAnyCardExpanded = Object.values(state).some(
      (school) => school.isExpanded
    );

    if (flags.hasExpandedCard !== isAnyCardExpanded) {
      actions.updateGlobalFlags({
        hasExpandedCard: isAnyCardExpanded,
      });
    }
  }, [state, flags, actions]);

  useEffect(() => {
    if (error) {
      handleNextClick();
    }
  }, [error]);

  if (showLoader) {
    return (
      <MultiStepLoader
        duration={LOADER_MIN_DURATION}
        isProcessing={isProcessing}
        onLoaderStart={() => {
          actions.updateGlobalFlags({ isLoading: true });
          LogInfo('MultiSchoolSelect: onLoaderStart');
        }}
        onLoaderComplete={() => {
          setShowLoader(false);
          actions.updateGlobalFlags({ isLoading: false });
          LogInfo('MultiSchoolSelect: onLoaderComplete');
        }}
      />
    );
  }

  const multiSchoolSelectClass = cx({
    multiSchoolSelect: true,
    'multiSchoolSelect multiSchoolSelect--hasExpanded': flags.hasExpandedCard,
    'multiSchoolSelect multiSchoolSelect--isSubmitting': isSubmitting,
  });

  return (
    <MultiSchoolSelectContext.Provider value={multiSchoolSelectState}>
      <div className={multiSchoolSelectClass}>
        <div className="multiSchoolSelect__inner">
          <div className="multiSchoolSelect__title">
            Click the <b>Request Info</b> button to get more information on
            these programs.
          </div>
          <MultiSchoolSelectList
            state={state}
            formSpeed={formSpeed}
            isTransitioning={isTransitioning}
            setIsTransitioning={setIsTransitioning}
            isMobile={isMobile}
          />
        </div>
      </div>
    </MultiSchoolSelectContext.Provider>
  );
}
