/* eslint-disable camelcase */
import {
  GooglePlace,
  GooglePlaceAddressComponent,
  GooglePlaceAddressComponentType,
} from 'types';

export function getGooglePlaceAddressParts(answer: GooglePlace): {
  street: string;
  city: string;
  state: string;
  zip: string;
  streetNumber: string;
  streetRoute: string;
} {
  const addressComponentMap: {
    [__T in GooglePlaceAddressComponentType]?: GooglePlaceAddressComponent;
  } = {};

  answer.addressComponents.forEach((component) => {
    component.types.forEach((type) => {
      addressComponentMap[type] = component;
    });
  });

  const {
    street_number,
    route,
    neighborhood,
    locality,
    administrative_area_level_1,
    postal_code,
  } = addressComponentMap;

  const streetNumber = street_number?.longText || '';
  const streetRoute = route?.longText || '';
  const street = `${streetNumber} ${streetRoute}`;
  const city = (neighborhood || locality)?.longText || '';
  const state = administrative_area_level_1?.longText || '';
  const zip = postal_code?.longText || '';

  return { street, city, state, zip, streetNumber, streetRoute };
}
