import React from 'react';

interface ApplicationLinkProps {
  url: string | null;
  buttonText: string;
}

function ApplicationLink(props: ApplicationLinkProps): JSX.Element | null {
  const { url, buttonText } = props;

  if (!url) {
    return null;
  }
  return (
    <a
      className="ctaButton ctaButton--applicationLink"
      data-track-click="click apply"
      data-testid="ctaButton--applicationLink"
      href={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {buttonText}
    </a>
  );
}

export default ApplicationLink;
